<template>
  <div class="alert alert-danger mb-5">
    {{ $t("dataStructures.isLockedHint") }}
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
