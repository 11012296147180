var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header py-3"},[_c('div',{staticClass:"card-title d-flex align-items-center"},[_c('h3',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" "+_vm._s(_vm.$t("dynamicModels.detailsBasic"))+" ")])]),_c('div',{staticClass:"card-toolbar"},[_c('button',{staticClass:"btn btn-secondary mr-2",on:{"click":function($event){return _vm.$router.push({ name: _vm.configuration.tableRoute })}}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c('button',{staticClass:"btn",class:_vm.dataStructure.is_locked ? 'btn-secondary' : 'btn-primary',attrs:{"disabled":_vm.dataStructure.is_locked},on:{"click":_vm.updateDataStructure}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])])]),(_vm.isBusy)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('div',{staticClass:"card-body"},[(_vm.dataStructure.is_locked)?_c('AlertIsLocked'):_vm._e(),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-xl-3"}),_c('div',{staticClass:"col-lg-9 col-xl-6"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("dataStructures.baseData"))+":")])])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label"},[_c('div',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("dataStructures.label")))])]),_c('div',{staticClass:"col-md-9 col-lg-6 col-xl-4"},[_c('b-form-input',{staticClass:"form-control",class:{
            'is-invalid': _vm.$v.dataStructure.label.$error,
            'is-valid':
              _vm.$v.dataStructure.label.required &&
              _vm.$v.dataStructure.label.minLength
          },attrs:{"trim":"","disabled":_vm.dataStructure.is_locked},on:{"input":function($event){return _vm.$v.dataStructure.label.$touch()}},model:{value:(_vm.dataStructure.label),callback:function ($$v) {_vm.$set(_vm.dataStructure, "label", $$v)},expression:"dataStructure.label"}})],1)]),(_vm.appVersion < 2)?_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("dataStructures.version"))+" ")]),_c('div',{staticClass:"col-md-9 col-lg-6 col-xl-4"},[_c('b-form-input',{staticClass:"form-control",class:{
            'is-invalid': _vm.$v.dataStructure.version.$error,
            'is-valid': _vm.$v.dataStructure.version.required
          },attrs:{"type":"number","trim":"","debounce":"300","disabled":_vm.dataStructure.is_locked || _vm.dataStructure.is_system_structure},on:{"change":function($event){return _vm.$v.dataStructure.version.$touch()}},model:{value:(_vm.$v.dataStructure.version.$model),callback:function ($$v) {_vm.$set(_vm.$v.dataStructure.version, "$model", $$v)},expression:"$v.dataStructure.version.$model"}})],1)]):_vm._e(),(_vm.configuration.area !== 0)?_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-3 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("dataStructures.published"))+" ")]),_c('div',{staticClass:"col-9 d-flex align-center"},[_c('span',{staticClass:"switch switch-sm mr-1"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataStructure.is_published),expression:"dataStructure.is_published"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.dataStructure.is_published)?_vm._i(_vm.dataStructure.is_published,null)>-1:(_vm.dataStructure.is_published)},on:{"change":function($event){var $$a=_vm.dataStructure.is_published,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.dataStructure, "is_published", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.dataStructure, "is_published", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.dataStructure, "is_published", $$c)}}}}),_c('span')])]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive",value:(
            _vm.$t('dataStructures.publishedTooltip')
          ),expression:"\n            $t('dataStructures.publishedTooltip')\n          ",modifiers:{"top":true,"noninteractive":true}}],staticClass:"fal fa-question-circle"})])]):_vm._e(),(_vm.appVersion < 2)?_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-3 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("dataStructures.dataVersioning"))+" ")]),_c('div',{staticClass:"col-9 d-flex align-center"},[_c('span',{staticClass:"switch switch-sm mr-1"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataStructure.useDataVersioning),expression:"dataStructure.useDataVersioning"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.dataStructure.useDataVersioning)?_vm._i(_vm.dataStructure.useDataVersioning,null)>-1:(_vm.dataStructure.useDataVersioning)},on:{"change":function($event){var $$a=_vm.dataStructure.useDataVersioning,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.dataStructure, "useDataVersioning", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.dataStructure, "useDataVersioning", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.dataStructure, "useDataVersioning", $$c)}}}}),_c('span')])]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive",value:(
            _vm.$t('dataStructures.dataVersioningTooltip')
          ),expression:"\n            $t('dataStructures.dataVersioningTooltip')\n          ",modifiers:{"top":true,"noninteractive":true}}],staticClass:"fal fa-question-circle"})])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }