<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("dynamicModels.detailsBasic") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: configuration.tableRoute })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button
          class="btn"
          :class="dataStructure.is_locked ? 'btn-secondary' : 'btn-primary'"
          :disabled="dataStructure.is_locked"
          @click="updateDataStructure"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body">
      <AlertIsLocked v-if="dataStructure.is_locked" />
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">{{ $t("dataStructures.baseData") }}:</h5>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">
          <div class="mt-3">{{ $t("dataStructures.label") }}</div>
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <b-form-input
            v-model="dataStructure.label"
            class="form-control"
            :class="{
              'is-invalid': $v.dataStructure.label.$error,
              'is-valid':
                $v.dataStructure.label.required &&
                $v.dataStructure.label.minLength
            }"
            trim
            :disabled="dataStructure.is_locked"
            @input="$v.dataStructure.label.$touch()"
          />
        </div>
      </div>

      <div class="form-group row align-items-center" v-if="appVersion < 2">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("dataStructures.version") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <b-form-input
            v-model="$v.dataStructure.version.$model"
            class="form-control"
            :class="{
              'is-invalid': $v.dataStructure.version.$error,
              'is-valid': $v.dataStructure.version.required
            }"
            type="number"
            trim
            debounce="300"
            :disabled="
              dataStructure.is_locked || dataStructure.is_system_structure
            "
            @change="$v.dataStructure.version.$touch()"
          />
        </div>
      </div>

      <div
        v-if="configuration.area !== 0"
        class="form-group row align-items-center"
      >
        <label class="col-3 col-form-label">
          {{ $t("dataStructures.published") }}
        </label>
        <div class="col-9 d-flex align-center">
          <span class="switch switch-sm mr-1">
            <label>
              <input v-model="dataStructure.is_published" type="checkbox" />
              <span></span>
            </label>
          </span>
          <i
            v-b-tooltip.top.noninteractive="
              $t('dataStructures.publishedTooltip')
            "
            class="fal fa-question-circle"
          />
        </div>
      </div>

      <div class="form-group row align-items-center" v-if="appVersion < 2">
        <label class="col-3 col-form-label">
          {{ $t("dataStructures.dataVersioning") }}
        </label>
        <div class="col-9 d-flex align-center">
          <span class="switch switch-sm mr-1">
            <label>
              <input
                v-model="dataStructure.useDataVersioning"
                type="checkbox"
              />
              <span></span>
            </label>
          </span>
          <i
            v-b-tooltip.top.noninteractive="
              $t('dataStructures.dataVersioningTooltip')
            "
            class="fal fa-question-circle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertIsLocked from "@/components/Admins/Settings/DataStructures/AlertIsLocked";
import { minLength, required, integer } from "vuelidate/lib/validators";

export default {
  components: {
    AlertIsLocked
  },
  props: ["configuration", "dataStructure", "isBusy"],
  validations: {
    dataStructure: {
      label: {
        required,
        minLength: minLength(3)
      },
      classname: {
        required,
        minLength: minLength(3),
        noWhiteSpaces: function (value) {
          return !value.includes(" ");
        }
      },
      tablename: {
        required,
        minLength: minLength(3),
        noWhiteSpaces: function (value) {
          return !value.includes(" ");
        }
      },
      version: { required, integer }
    }
  },
  methods: {
    updateDataStructure() {
      this.$emit("save");
    }
  }
};
</script>
