<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("dataStructures.dataStructures") }}</h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary" @click="routeToCreate">
          <i class="fal fa-plus"></i>
          {{ $t("dataStructures.create") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadDataStructures">
        <b-table
          responsive
          :items="dataStructures"
          :fields="fields"
          class="dataTable table table-head-custom"
          :filter="searchTitle"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(is_locked)="data">
            <span v-if="data.item.is_locked">
              <i class="fal fa-lock icon-lg text-danger" />
            </span>
            <span v-else>
              <i class="fal fa-lock-open icon-lg text-success" />
            </span>
          </template>
          <template #cell(actions)="data">
            <button
              v-if="!data.item.is_locked"
              v-b-tooltip.top.noninteractive="$t('dataStructures.openEditor')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click.left="openEditor(data.item.id)"
              @click.middle="openEditor(data.item.id)"
            >
              <i class="fal fa-paint-brush" />
            </button>
            <button
              v-b-tooltip.top.noninteractive="$t('dataStructures.configure')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click.left="editDataStructure(data.item.id)"
              @click.middle="editDataStructure(data.item.id)"
            >
              <i class="fal fa-pen" />
            </button>
            <button
              v-if="configuration.area === 0 && !data.item.is_locked"
              v-b-tooltip.top.noninteractive="$t('dataStructures.deleteTitle')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteDataStructure(data.item)"
            >
              <i class="fal fa-trash" />
            </button>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

let DataStructureService;

export default {
  components: { TableWrapper },
  props: ["configuration"],
  data() {
    return {
      breadcrumb: {
        0: [
          {
            title: this.$t("dataStructures.dataStructures"),
            route: ""
          },
          {
            title: this.$t("dataStructures.overview"),
            route: "/project/data-structures"
          }
        ],
        2: [
          {
            title: this.$t("dataStructures.dataStructures"),
            route: ""
          },
          {
            title: this.$t("dataStructures.overview"),
            route: "/partner/data-structures"
          }
        ],
        3: [
          {
            title: this.$t("menu.adminSettings"),
            route: ""
          },
          {
            title: this.$t("dataStructures.dataStructures"),
            route: ""
          },
          {
            title: this.$t("dataStructures.overview"),
            route: "/admin/settings/data-structures"
          }
        ]
      },
      searchTitle: "",
      dataStructures: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "label",
          label: this.$t("dataStructures.label"),
          sortable: true
        },
        {
          key: "version",
          label: this.$t("table.version"),
          sortable: true
        },
        {
          key: "primary",
          label: this.$t("table.primary"),
          sortable: true
        },
        {
          key: "is_locked",
          label: this.$t("table.isLocked"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "150px" }
        }
      ],
      // Meta info
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    DataStructureService = this.configuration.dataStructureService;

    this.setBreadcrumbs();
    this.loadDataStructures();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(
        SET_BREADCRUMB,
        this.breadcrumb[this.configuration.area]
      );
    },
    loadDataStructures() {
      if (!DataStructureService) {
        return;
      }
      this.isBusy = true;
      let params = { ...this.requestParams(), ...{ order: "id" } };
      let cancelToken = this.cancelToken();
      DataStructureService.getAll(params, cancelToken)
        .then(response => {
          this.dataStructures = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    editDataStructure(id) {
      if (this.configuration.detailsRoute === "") return;
      this.$router.push({
        name: this.configuration.detailsRoute,
        params: { id: id }
      });
    },
    deleteDataStructure(dataStructure) {
      let id = dataStructure.id;
      let name = dataStructure.classname;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("dataStructures.deleteTitle"),
          text: this.$t("dataStructures.deleteText", { name: name }),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel"),
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "#F64E60"
        })
        .then(result => {
          if (!result.isConfirmed) return;
          this.deleteDataStructureAction(id);
        });
    },
    deleteDataStructureAction(id) {
      this.isBusy = true;
      DataStructureService.delete(id)
        .then(response => {
          if (response.data.data.success) {
            this.$toast.fire({
              icon: "success",
              title: this.$t("dataStructures.deletedSuccess")
            });
            this.loadDataStructures();
            return;
          }
          this.$toast.fire({
            title:  response.data.data.error,
            icon: "error",
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    routeToCreate() {
      if (this.configuration.createRoute === "") return;
      this.$router.push({ name: this.configuration.createRoute });
    },
    openEditor(id) {
      if (this.configuration.editorRoute === "") return;
      this.$router.push({
        name: this.configuration.editorRoute,
        params: { id: id }
      });
    }
  }
};
</script>
