<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("dataStructures.standardSQLQueries") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: configuration.tableRoute })"
        >
          {{ $t("general.cancel") }}
        </button>
        <b-button
          v-if="!$v.$invalid"
          type="button"
          variant="primary"
          @click="update"
        >
          {{ $t("general.save") }}
        </b-button>
        <b-button v-else type="button" variant="secondary">
          {{ $t("general.save") }}
        </b-button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body">
      <StandardSQLQueriesTable
        :data-structure="dataStructure"
      ></StandardSQLQueriesTable>
    </div>
  </div>
</template>

<script>
import StandardSQLQueriesTable from "./StandardSQLQueriesTable";
import { bus } from "@/main";
import { required } from "vuelidate/lib/validators";

let DataStructureService;

export default {
  components: {
    StandardSQLQueriesTable
  },
  props: ["dataStructure", "configuration"],
  data() {
    return {
      isBusy: false
    };
  },
  validations: {
    dataStructure: {
      queries: {
        $each: {
          name: { required },
          query: { required }
        }
      }
    }
  },
  mounted() {
    DataStructureService = this.configuration.dataStructureService;

    bus.$on("deleteStandardQuery", this.delete);
  },
  beforeDestroy() {
    bus.$off("deleteStandardQuery", this.delete);
  },
  methods: {
    loadData() {
      this.isBusy = true;
      DataStructureService.get(this.dataStructure.id)
        .then(response => {
          this.dataStructure.queries = response.data.data.queries;
          this.isBusy = false;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
    update() {
      const dataStructureId = this.dataStructure.id;
      const queries = this.dataStructure.queries;
      queries.forEach(entry => {
        this.isBusy = true;
        if (typeof entry.id !== "undefined") {
          DataStructureService.updateQuery(entry)
            .then(() => {
              this.isBusy = false;
            })
            .catch(() => {
              this.isBusy = false;
            });
        } else {
          entry.data_structure_id = dataStructureId;
          DataStructureService.storeQuery(entry)
            .then(() => {
              this.loadData();
              this.isBusy = false;
            })
            .catch(() => {
              this.isBusy = false;
            });
        }
      });
    },
    delete(id) {
      DataStructureService.deleteQuery(id).then(response => {
        if (response.data.data.success) {
          this.$toast.fire({
            icon: "success",
            title: this.$t("dataStructures.queryDeleted")
          });
        }
      });
    }
  }
};
</script>
