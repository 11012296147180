var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.area === 0 && _vm.userType !== 'client' && !_vm.selectedClient && _vm.appVersion < 2)?_c('div',[_vm._v(" Client auswählen ")]):(_vm.area === 2 && _vm.userType === 'admin' && !_vm.selectedPartner && _vm.appVersion < 2)?_c('div',[_vm._v(" Partner auswählen ")]):_c('div',[(
        _vm.selectedConfiguration &&
        _vm.$route.name === _vm.selectedConfiguration.createRoute
      )?_c('Wizard',{attrs:{"configuration":_vm.selectedConfiguration}}):(
        _vm.selectedConfiguration &&
        _vm.$route.name.includes(_vm.selectedConfiguration.detailsRoute)
      )?_c('Details',{attrs:{"configuration":_vm.selectedConfiguration,"area":_vm.area}}):(
        _vm.selectedConfiguration &&
        _vm.$route.name === _vm.selectedConfiguration.editorRoute
      )?_c('Editor',{attrs:{"configuration":_vm.selectedConfiguration,"mock-data":false}}):(_vm.selectedConfiguration)?_c('Table',{attrs:{"configuration":_vm.selectedConfiguration}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }