<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-body pt-4">
            <div class="d-flex justify-content-end"></div>
            <div class="row py-3">
              <div class="col-12">
                <div class="font-weight-bolder font-size-h5 text-dark-75">
                  <b-skeleton
                    v-if="!dataStructure.id"
                    type="text"
                    height="1.5rem"
                  />
                  <span>{{ dataStructure.currentLabel }}</span>
                </div>
                <div class="text-muted">
                  <b-skeleton
                    v-if="!dataStructure.id"
                    type="text"
                    width="60%"
                  />
                  <span>{{ $t("dataStructures.dataStructureEdit") }}</span>
                </div>
              </div>
            </div>
            <div v-if="appVersion < 2"
              class="form-group row justify-space-between align-items-center mb-1"
            >
              <div class="col-auto">
                {{ $t("dataStructures.lock") }}
              </div>
              <div class="col-auto row align-items-center">
                <i class="fal fa-lock-open mr-1" />
                <span class="switch switch-sm">
                  <label>
                    <input
                      v-model="dataStructure.is_locked"
                      type="checkbox"
                      :disabled="isBusy"
                      @change="handleLock"
                    />
                    <span></span>
                  </label>
                </span>
                <i class="fal fa-lock mr-3 ml-1" />
              </div>
            </div>
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route }"
                  custom
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{ active: $route.name === item.route }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 client-details">
        <BaseData
          v-if="$route.name === routeBaseData"
          :key="componentKey"
          :configuration="configuration"
          :data-structure="dataStructure"
          :is-busy="isBusy"
          @save="updateDataStructure"
        />

        <Configuration
          v-if="$route.name === routeConfiguration"
          :key="componentKey"
          :configuration="configuration"
          :data-structure="dataStructure"
          :is-busy="isBusy"
          @save="updateDataStructure"
        />

        <StandardSQLQueries
          v-if="$route.name === routeSQLQueries && appVersion < 2"
          :data-structure="dataStructure"
          :configuration="configuration"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseData from "@/components/Admins/Settings/DataStructures/Details/Components/BaseData";
import Configuration from "@/components/Admins/Settings/DataStructures/Details/Components/Configuration";
import StandardSQLQueries from "@/components/Admins/Settings/DataStructures/Details/Components/StandardSQLQueries";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

let DataStructureService;

export default {
  components: {
    BaseData,
    Configuration,
    StandardSQLQueries
  },
  props: ["configuration"],
  data() {
    return {
      breadcrumbs: {
        0: [
          {
            title: this.$t("dataStructures.dataStructures"),
            route: "/project/data-structures"
          },
          {
            title: "",
            route: "/project/data-structures/"
          }
        ],
        2: [
          {
            title: this.$t("dataStructures.dataStructures"),
            route: "/partner/data-structures"
          },
          {
            title: "",
            route: "/partner/data-structures/"
          }
        ],
        3: [
          {
            title: this.$t("menu.adminSettings"),
            route: ""
          },
          {
            title: this.$t("dataStructures.dataStructures"),
            route: "/admin/settings/data-structures"
          },
          {
            title: "",
            route: "/admin/settings/data-structures/"
          }
        ]
      },
      dataStructure: {},
      tabIndex: 0,
      routeBaseData: "",
      routeConfiguration: "",
      routeSQLQueries: "",
      tabNavigation: [],
      componentKey: 1,
      isBusy: false
    };
  },
  watch: {
    $route: function () {
      this.checkRoute();
    }
  },
  mounted() {
    DataStructureService = this.configuration.dataStructureService;

    this.loadDataStructure();
  },
  methods: {
    setRoutes() {
      this.routeBaseData = this.configuration.detailsRoute + "BaseData";
      this.routeConfiguration =
        this.configuration.detailsRoute + "Configuration";
      this.routeSQLQueries =
        this.configuration.detailsRoute + "StandardSQLQueries";

      this.tabNavigation = [
        {
          route: this.routeBaseData,
          label: "dataStructures.baseData",
          icon: "fal fa-sliders"
        },
      ];

      if(this.appVersion < 2) {
        this.tabNavigation.push(
            {
              route: this.routeSQLQueries,
              label: "dataStructures.standardSQLQueries",
              icon: "fal fa-warehouse"
            }
        )
      }


      if (this.dataStructure.config && this.dataStructure.config.length !== 0) {
        this.tabNavigation.splice(1, 0, {
          route: this.routeConfiguration,
          label: "dataStructures.stepConfig",
          icon: "fal fa-gear"
        });
      }
    },
    checkRoute() {
      if (this.$route.name === this.configuration.detailsRoute)
        this.$router.push({ name: this.routeBaseData });
    },
    setBreadcrumbs() {
      let breadcrumb = this.breadcrumbs[this.configuration.area];
      breadcrumb[breadcrumb.length - 1].title = this.dataStructure.currentLabel;
      breadcrumb[breadcrumb.length - 1].route += this.dataStructure.id;
      this.$store.dispatch(SET_BREADCRUMB, breadcrumb);
    },
    loadDataStructure() {
      this.isBusy = true;
      let id = this.$route.params.id;
      DataStructureService.get(id)
        .then(response => {
          this.dataStructure = response.data.data;
          this.dataStructure.tablename = this.dataStructure.raw_tablename;
          if (
            this.dataStructure.config.checkStructure == undefined &&
            [0, 1, 2].includes(this.dataStructure.config.type)
          ) {
            this.dataStructure.config.checkStructure = false;
          }
          if (
            this.dataStructure.config.onEmptyData == undefined &&
            [0, 1, 2].includes(this.dataStructure.config.type)
          ) {
            this.dataStructure.config.onEmptyData = "";
          }
          this.setRoutes();
          this.checkRoute();
          this.setBreadcrumbs();
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    handleLock() {
      let isLocked = this.dataStructure.is_locked;
      if (isLocked) this.lockDataStructure();
      else this.unlockDataStructure();
    },
    updateDataStructure() {
      this.isBusy = true;
      const data = this.dataStructure;
      DataStructureService.update(data.id, data)
        .then(response => {
          this.dataStructure = response.data.data;
          this.dataStructure.tablename = this.dataStructure.raw_tablename;
          this.isBusy = false;

          this.$toast.fire({
            title: this.$t("dataStructures.storeSuccessTitle"),
            icon: "success"
          });
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    lockDataStructure() {
      this.isBusy = true;
      DataStructureService.update(this.dataStructure.id, this.dataStructure)
        .then(() => {
          this.loadDataStructure();
          this.componentKey++;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.loadDataStructure();
        });
    },
    unlockDataStructure() {
      this.isBusy = true;
      DataStructureService.unlock(this.dataStructure.id)
        .then(() => {
          this.loadDataStructure();
          this.componentKey++;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.loadDataStructure();
        });
    }
  }
};
</script>
