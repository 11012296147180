<template>
  <div>
    <div v-if="area === 0 && userType !== 'client' && !selectedClient && appVersion < 2">
      Client auswählen
    </div>
    <div v-else-if="area === 2 && userType === 'admin' && !selectedPartner && appVersion < 2">
      Partner auswählen
    </div>
    <div v-else>
      <Wizard
        v-if="
          selectedConfiguration &&
          $route.name === selectedConfiguration.createRoute
        "
        :configuration="selectedConfiguration"
      />
      <Details
        v-else-if="
          selectedConfiguration &&
          $route.name.includes(selectedConfiguration.detailsRoute)
        "
        :configuration="selectedConfiguration"
        :area="area"
      />
      <Editor
        v-else-if="
          selectedConfiguration &&
          $route.name === selectedConfiguration.editorRoute
        "
        :configuration="selectedConfiguration"
        :mock-data="false"
      />
      <Table
        v-else-if="selectedConfiguration"
        :configuration="selectedConfiguration"
      />
    </div>
  </div>
</template>

<script>
import Details from "@/components/Admins/Settings/DataStructures/Details/Details";
import Table from "@/components/Admins/Settings/DataStructures/Table";
import Editor from "@/components/Admins/Settings/DataStructures/Editor/Editor";
import Wizard from "@/components/Admins/Settings/DataStructures/Wizard/Wizard";

import {
  DataStructures,
  DataStructureTemplates
} from "@/components/Admins/Settings/DataStructures/dataStructures";
import { mapGetters } from "vuex";

const AREAS = [
  {
    name: "project",
    id: 0
  },
  {
    name: "partner",
    id: 2
  },
  {
    name: "admin",
    id: 3
  }
];

let DataStructureService;

export default {
  components: { Table, Details, Editor, Wizard },
  props: [],
  data() {
    return {
      area: {},
      userType: "",
      headers: [],
      configurations: {
        0: {
          area: 0,
          createRoute: "projectDataStructuresCreate",
          detailsRoute: "projectDataStructuresDetails",
          editorRoute: "projectDataStructuresEditor",
          tableRoute: "projectDataStructures"
        },
        2: {
          area: 2,
          createRoute: "partnerDataStructuresCreate",
          detailsRoute: "partnerDataStructuresDetails",
          editorRoute: "partnerDataStructuresEditor",
          tableRoute: "partnerDataStructures"
        },
        3: {
          area: 3,
          createRoute: "adminSettingsDataStructuresCreate",
          detailsRoute: "adminSettingsDataStructuresDetails",
          editorRoute: "adminSettingsDataStructuresEditor",
          tableRoute: "adminSettingsDataStructures"
        }
      }
    };
  },
  computed: {
    ...mapGetters(["selectedPartner", "selectedClient"]),
    selectedConfiguration: function () {
      let configuration = this.configurations[this.area];
      if (configuration) {
        configuration.dataStructureService = DataStructureService;
      }
      return configuration;
    }
  },
  watch: {
    selectedPartner: function () {
      this.setDataStructuresHeader();
    },
    selectedClient: function () {
      this.setDataStructuresHeader();
    }
  },
  mounted() {
    this.area = AREAS.find(area => this.$route.name.startsWith(area.name)).id;
    this.userType = this.$store.getters.userType;

    let isAdmin = this.$route.name.toLowerCase().startsWith("admin");
    let isPartner = this.$route.name.toLowerCase().startsWith("partner");
    DataStructureService =
      !isAdmin && !isPartner
        ? new DataStructures()
        : new DataStructureTemplates(isAdmin);

    this.setDataStructuresHeader();
  },
  methods: {
    setDataStructuresHeader() {
      // adminArea
      // partnerArea logged in as partner
      // projectArea logged in as client
      if (
        this.area === 3 ||
        (this.area === 2 && this.userType === "partner") ||
        (this.area === 0 && this.userType === "client")
      ) {
        DataStructureService.setOptionalHeaders({});
        return;
      }

      // partnerArea logged in as admin
      if (this.area === 2 && this.selectedPartner) {
        DataStructureService.setOptionalHeaders({
          headers: {
            "x-api-key": "Bearer " + this.selectedPartner.api_token
          }
        });
        return;
      }

      // projectArea logged in as admin
      if (this.userType === "admin" && this.selectedClient) {
        DataStructureService.setOptionalHeaders({
          headers: {
            "x-api-key": "Bearer " + this.selectedClient.api_token
          }
        });
        return;
      }

      // projectArea logged in as partner
      if (
        this.userType === "partner" &&
        this.selectedPartner &&
        this.selectedClient
      ) {
        DataStructureService.setOptionalHeaders({
          headers: {
            "x-partner-key": "Bearer " + this.selectedPartner.api_token,
            "x-api-key": "Bearer " + this.selectedClient.api_token
          }
        });
      }
    }
  }
};
</script>
