<template>
  <div>
    <b-table
      responsive
      :items="dataStructure.queries"
      :fields="fields"
      class="dataTable table table-head-custom table-data-structures"
      table-class="b-table-fixed"
      bordered
      :show-empty="true"
      :empty-text="$t('dataStructures.emptyTextQuery')"
    >
      <template #cell(name)="data">
        <div class="form-group mb-0">
          <b-form-input
            v-model="data.item.name"
            type="text"
            class="form-control"
            :class="
              !$v.dataStructure.queries.$each[data.index].name.required
                ? 'is-invalid'
                : 'is-valid'
            "
          />
        </div>
      </template>
      <template #cell(description)="data">
        <b-form-input
          v-model="data.item.description"
          type="text"
          class="form-control"
        />
      </template>
      <template #cell(query)="data">
        <b-form-textarea
          v-model="data.item.query"
          rows="1"
          style="min-height: 38px; height: 38px"
          class="form-control"
          :class="
            !$v.dataStructure.queries.$each[data.index].query.required
              ? 'is-invalid'
              : 'is-valid'
          "
        />
      </template>
      <template #cell(is_standard_query)="data">
        <div class="text-center">
          <span class="switch switch-sm mr-1">
            <label>
              <input v-model="data.item.is_standard_query" type="checkbox" />
              <span></span>
            </label>
          </span>
        </div>
      </template>
      <template #cell(actions)="data">
        <div class="d-flex flex-nowrap">
          <button
            class="btn btn-secondary btn-icon btn-sm mr-1"
            @click="duplicateRow(data.index)"
          >
            <i class="fal fa-copy" />
          </button>
          <button
            class="btn btn-secondary btn-icon btn-sm"
            @click="deleteRow(data.index)"
          >
            <i class="fal fa-trash" />
          </button>
        </div>
      </template>
    </b-table>
    <button class="btn btn-primary" @click="addQuery">
      <i class="fal fa-plus" />
      {{ $t("dataStructures.addQuery") }}
    </button>
  </div>
</template>

<script>
import { formatDate } from "@/components/Tools/modifiers";
import { bus } from "@/main";
import { required } from "vuelidate/lib/validators";
import Auth from "@/components/General/Auth/auth";

export default {
  components: {},
  props: ["dataStructure"],
  data() {
    return {
      userType: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          thStyle: { "min-width": "70px", "max-width": "70px", width: "70px" },
          sortable: true
        },
        {
          key: "name",
          label: this.$t("dataStructures.name"),
          thStyle: {
            "min-width": "250px",
            "max-width": "250px",
            width: "250px"
          },
          sortable: true
        },
        {
          key: "description",
          label: this.$t("dataStructures.description"),
          thStyle: {
            "min-width": "350px",
            "max-width": "350px",
            width: "350px"
          },
          sortable: true
        },
        {
          key: "query",
          label: this.$t("dataStructures.sqlQuery"),
          sortable: true,
          thStyle: { "min-width": "350px", width: "350px" }
        },
        {
          key: "is_standard_query",
          label: this.$t("dataStructures.standardQuery"),
          sortable: true,
          thStyle: { "min-width": "150px", width: "150px" }
        },
        {
          key: "updated_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.updatedAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ]
    };
  },
  validations: {
    dataStructure: {
      queries: {
        $each: {
          name: { required },
          query: { required }
        }
      }
    }
  },
  created() {
    this.userType = Auth.getUserType();
  },
  methods: {
    addQuery() {
      this.dataStructure.queries.push({
        name: this.dataStructure.raw_tablename,
        description: this.dataStructure.raw_tablename,
        query: "SELECT * FROM " + this.dataStructure.raw_tablename,
        created_at: "",
        updated_at: ""
      });
    },
    formatDateAssigned(value) {
      if (value.length) {
        return formatDate(value);
      }
      return "";
    },
    duplicateRow(index) {
      let copy = Object.assign({}, this.dataStructure.queries[index]);
      if (!copy.name.includes("- COPY")) {
        copy.name += " - COPY";
      }
      delete copy.id;
      copy.created_at = "";
      copy.updated_at = "";
      this.dataStructure.queries.push(copy);
    },
    deleteRow(index) {
      this.dataStructure.queries.forEach((entry, queryIndex) => {
        if (typeof entry.id !== "undefined" && index === queryIndex) {
          bus.$emit("deleteStandardQuery", entry.id);
        }
      });
      this.dataStructure.queries.splice(index, 1);
    }
  }
};
</script>
