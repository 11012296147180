<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("dataStructures.stepConfig") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: configuration.tableRoute })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button
          v-if="dataStructure.config.type === 0 || dataStructure.config.type"
          class="btn btn-primary"
          :disabled="dataStructure.is_locked"
          @click="updateDataStructure"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body">
      <ConfigCSV
        v-if="dataStructure.config && dataStructure.config.type === 0"
        ref="step-basic-config-0"
        :initial-config="dataStructure.config"
      />
      <ConfigXML
        v-else-if="dataStructure.config && dataStructure.config.type === 1"
        ref="step-basic-config-1"
        :initial-config="dataStructure.config"
      />
      <ConfigJSON
        v-else-if="dataStructure.config && dataStructure.config.type === 2"
        ref="step-basic-config-2"
        :initial-config="dataStructure.config"
      />
      <div v-else>
        {{ $t("general.noConfigAvailable") }}
      </div>
      <div v-if="[0, 1, 2].includes(dataStructure.config.type)">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">
            <div class="mt-3">{{ $t("dataStructures.onEmptyData") }}</div>
          </label>
          <div class="col-md-9 col-lg-8 col-xl-4">
            <div class="input-group">
              <v-select
                v-model="dataStructure.config.onEmptyData"
                class="form-control"
                :items="onEmptyDataOptions"
                item-text="label"
                item-value="value"
                :menu-props="{ offsetY: true }"
              />
            </div>
            <span class="form-text text-muted">{{
              $t("dataStructures.onEmptyDataTooltip")
            }}</span>
          </div>
        </div>
      </div>

      <div v-if="[0, 1, 2].includes(dataStructure.config.type)">
        <div class="form-group row align-items-center">
          <label class="col-3 col-form-label">
            {{ $t("dataStructures.checkStructure") }}
          </label>
          <div class="col-9 d-flex align-center">
            <span class="switch switch-sm mr-1">
              <label>
                <input
                  v-model="dataStructure.config.checkStructure"
                  type="checkbox"
                />
                <span></span>
              </label>
            </span>
            <i
              v-b-tooltip.top.noninteractive="
                $t('dataStructures.checkStructureTooltip')
              "
              class="fal fa-question-circle"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigCSV from "@/components/Projects/DynamicModels/DynamicModel/Configs/CSV";
import ConfigXML from "@/components/Projects/DynamicModels/DynamicModel/Configs/XML";
import ConfigJSON from "@/components/Projects/DynamicModels/DynamicModel/Configs/JSON";

export default {
  components: {
    ConfigCSV,
    ConfigXML,
    ConfigJSON
  },
  props: ["configuration", "dataStructure", "isBusy"],
  data() {
    return {
      onEmptyDataOptions: [
        {
          label: "-",
          value: ""
        },
        {
          label: "Warnung",
          value: "warn"
        },
        {
          label: "Fehler",
          value: "error"
        }
      ]
    };
  },
  mounted() {
    // Parameter is not checked correctly in core - parameter has to be switched
    this.dataStructure.config.forceEncoding =
      !this.dataStructure.config.forceEncoding;
  },
  methods: {
    updateDataStructure() {
      this.dataStructure.config = this.getConfig();
      this.$emit("save");
    },
    getConfig() {
      let config =
        this.$refs[
          "step-basic-config-" + this.dataStructure.config.type
        ].fetchConfig();
      config.onEmptyData = this.dataStructure.config.onEmptyData;
      config.checkStructure = this.dataStructure.config.checkStructure;
      return config;
    }
  }
};
</script>
